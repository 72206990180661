// src/components/Support/SupportCard.jsx

import React from 'react';

function SupportCard({ title, imgSrc, description, onClick }) {
    return (
        <div className="support-card" onClick={onClick} role="button" tabIndex={0} onKeyPress={(e) => { if (e.key === 'Enter') onClick(); }}>
            <div className="support-card-left">
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
            <img src={imgSrc} alt={title} className="support-card-img" />
        </div>
    );
}

export default SupportCard;

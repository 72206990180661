import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './NotFound.css';
import BaseLayout from '../components/Base/BaseLayout';
import { useTranslation } from 'react-i18next';

function NotFound() {
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('Page Not Found');
    }, []);

    const handleBackClick = () => {
        history.push("/");
    };

    return (
        <BaseLayout>
            <div className="error-404-container">
                <div className="error-404-content">
                    <h1 className="error-404-code">404</h1>
                    <h2 className="error-404-message">{t("We can't seem to find that")}</h2>
                    <p className="error-404-description">{t("The page you're looking for doesn't exist or has been moved")}</p>
                    <div className="error-404-button" onClick={handleBackClick}>
                        <div className="error-404-button-background"></div>
                        <div className="error-404-button-content">
                            <img src="/assets/icons/arrow-left.svg" alt={t('Arrow Left')} className="error-404-button-icon" />
                            <span className="error-404-button-text">{t('Back to home')}</span>
                        </div>
                    </div>
                </div>
            </div>
        </BaseLayout>
    );
}

export default NotFound;

// src/pages/TermsOfUse.tsx

import React from 'react';
import './Information.css';
import BaseLayout from '../../components/Base/BaseLayout';
import { useTranslation } from 'react-i18next';

function TermsOfUse() {
    const { t } = useTranslation();

    return (
        <BaseLayout>
            <div className="info-page">
                <div className="info-content">
                    <div className="info-text-container">
                        <h1 className="info-title">{t('Terms of Use')}</h1>

                        {/* Section 1 */}
                        <h2 className="info-subtitle">{t('1. OVERVIEW AND DEFINED TERMS')}</h2>

                        <p className="info-paragraph">
                            {t('1.1')} <br />
                            {t('You are reading and agreeing to these Terms of Use (“Terms”), which constitute an agreement between you, the user of the Platform (whom we refer to as "you", "your" or the "user" in these Terms), and us. We are Bayzar, the operator of the Platform and provider of Bayzar Products related to our Platform.')}
                        </p>

                        <p className="info-paragraph">
                            {t('1.2')} <br />
                            {t('These Terms apply to your interaction with Bayzar, a company incorporated in the Republic of Iraq.')}
                        </p>

                        <p className="info-paragraph">
                            {t('1.3')} <br />
                            {t('We reserve the right to amend or terminate these Terms at any time at our sole discretion. Our right to terminate or amend these Terms shall not affect any other rights or remedies we may have concerning any breach or accrued rights, obligations, or liabilities before such termination or amendment.')}
                        </p>

                        <p className="info-paragraph">
                            {t('1.4')} <br />
                            {t('We provide a venue for Sellers and Buyers to negotiate and complete Transactions amongst themselves. Our Platform helps facilitate Transactions in connection with User Content. In this regard, we offer Bayzar Products to Sellers and Buyers to enable the listing process, improve listing visibility, and enhance the overall user experience.')}
                        </p>

                        <p className="info-paragraph">
                            {t('1.5')} <br />
                            {t('To improve your experience, we may need to collect information about you. Please review our Privacy Policy for more details about our data collection and processing practices.')}
                        </p>

                        <p className="info-paragraph">
                            {t('1.6')} <br />
                            {t('You may access the Platform only through our app, standard web, and mobile browsers, but not through any robot, spider, offline reader, or other manual or automatic device, tool, or process to retrieve, index, or data mine the Platform. As an exception, operators of public search engines may use spiders to copy materials from the Platform solely for the purpose of creating publicly available searchable indices of the materials.')}
                        </p>

                        <p className="info-paragraph">
                            {t('1.7')} <br />
                            {t('We reserve the right to prevent you at any time from using the Platform and/or any Bayzar Products (or any part thereof).')}
                        </p>

                        <p className="info-paragraph">
                            {t('1.8')} <br />
                            {t('The following terms are defined as follows in these Terms:')}
                        </p>

                        <ul className="info-list">
                            <li className="info-list-item">
                                {t('"Account": Your account with Bayzar.')}
                            </li>
                            <li className="info-list-item">
                                {t('"Buyer": A user browsing the Platform, searching for any product or service, and/or contacting another user with the intent to purchase any product or service offered by them.')}
                            </li>
                            <li className="info-list-item">
                                {t('"Bayzar Products": Any and all products and services offered or provided by us via the Platform, whether free or paid.')}
                            </li>
                            <li className="info-list-item">
                                {t('"Platform": Any websites, mobile applications, electronic communications, or services operated by Bayzar.')}
                            </li>
                            <li className="info-list-item">
                                {t('"Register": The process of creating an Account on the Platform.')}
                            </li>
                            <li className="info-list-item">
                                {t('"Seller": A user who uploads any User Content on the Platform intending to sell or offer any product or service to other users.')}
                            </li>
                            <li className="info-list-item">
                                {t('"Transaction": A transaction between a Seller and a Buyer facilitated by us on the Platform.')}
                            </li>
                            <li className="info-list-item">
                                {t('"User Content": Any listings, posts, offerings, ads, user-generated content, including messages between users, files, images, videos, sounds, and other content submitted through the Platform.')}
                            </li>
                        </ul>

                        {/* Section 2 */}
                        <h2 className="info-subtitle">{t('2. REGISTRATION AND YOUR ACCOUNT')}</h2>

                        <p className="info-paragraph">
                            {t('2.1')} <br />
                            {t('You are not required to Register to use the Platform. However, depending on your usage, we may require you to Register to continue using the Platform, and you must Register to access many of Bayzar’s Products. Creating an Account is free. With an Account, you will be able to create User Content, purchase Bayzar Products, track your search history, chat with other users, and manage your Account settings.')}
                        </p>

                        <p className="info-paragraph">
                            {t('2.2')} <br />
                            {t('Registration is straightforward and may require you to provide a username, password, and a valid phone number or email address. We reserve the right to request additional information to improve the user experience.')}
                        </p>

                        <p className="info-paragraph">
                            {t('2.3')} <br />
                            {t('Each individual is limited to having one Account on the Platform. Duplicate Accounts will be deleted by us.')}
                        </p>

                        <p className="info-paragraph">
                            {t('2.4')} <br />
                            {t('You must keep your Account password confidential and notify us immediately if any unauthorized third party gains access to your password or if there is any unauthorized use of your Account. You are responsible for the confidentiality and use of your password.')}
                        </p>

                        <p className="info-paragraph">
                            {t('2.5')} <br />
                            {t('We may send you administrative and promotional communications from time to time, as well as updates regarding your Account activity and purchases.')}
                        </p>

                        {/* Section 3 */}
                        <h2 className="info-subtitle">{t('3. OUR PRODUCTS AND SERVICES')}</h2>

                        <p className="info-paragraph">
                            {t('3.1')} <br />
                            {t('We offer you a variety of Bayzar Products, all of which are non-refundable. Bayzar Products are categorized into:')}
                        </p>

                        <ul className="info-list">
                            <li className="info-list-item">
                                {t('Listing Capability Products: Allow Sellers to create User Content to list items for viewing by Buyers.')}
                            </li>
                            <li className="info-list-item">
                                {t('Visibility Products: Enable Sellers to improve the visibility of their listings or repost them for a fee.')}
                            </li>
                            <li className="info-list-item">
                                {t('Transaction-Related Products: In certain cases, we may assist users with completing Transactions for a fee.')}
                            </li>
                            <li className="info-list-item">
                                {t('Other Products: Such as the ability to remove ads from the Platform or improve your overall experience.')}
                            </li>
                        </ul>

                        <p className="info-paragraph">
                            {t('3.2')} <br />
                            {t('We reserve the right to modify, update, or discontinue any Bayzar Products or services at any time.')}
                        </p>

                        {/* Section 4 */}
                        <h2 className="info-subtitle">{t('4. USER CONTENT')}</h2>

                        <p className="info-paragraph">
                            {t('4.1')} <br />
                            {t('The Platform enables Account holders to submit User Content. User Content may only be submitted in the format prescribed on the Platform.')}
                        </p>

                        <p className="info-paragraph">
                            {t('4.2')} <br />
                            {t('By submitting any User Content, you represent and warrant that you are the owner or have the necessary permissions to submit the content and grant the required licenses outlined in these Terms.')}
                        </p>

                        {/* Section 5 */}
                        <h2 className="info-subtitle">{t('5. INTELLECTUAL PROPERTY')}</h2>

                        <p className="info-paragraph">
                            {t('5.1')} <br />
                            {t('We grant you a limited, non-transferable, non-exclusive, revocable license to access and use the Platform under these Terms.')}
                        </p>

                        <p className="info-paragraph">
                            {t('5.2')} <br />
                            {t('All intellectual property rights related to the Platform and Bayzar Products remain our property or the property of our licensors. You may not exploit, distribute, copy, or otherwise use any content on the Platform without prior written permission from us.')}
                        </p>

                        {/* Section 6 */}
                        <h2 className="info-subtitle">{t('6. ADVERTISEMENTS AND DATA PROTECTION')}</h2>

                        <p className="info-paragraph">
                            {t('6.1')} <br />
                            {t('Advertisements on the Platform may be delivered on our behalf by third parties who may place cookies on your browser. For more details, see our Privacy Policy.')}
                        </p>

                        {/* Section 7 */}
                        <h2 className="info-subtitle">{t('7. LIMITATION OF LIABILITY')}</h2>

                        <p className="info-paragraph">
                            {t('7.1')} <br />
                            {t('We are not liable for:')}
                        </p>

                        <ul className="info-list">
                            <li className="info-list-item">
                                {t('Any loss of income or revenue.')}
                            </li>
                            <li className="info-list-item">
                                {t('Loss of business opportunities.')}
                            </li>
                            <li className="info-list-item">
                                {t('Any harm to reputation.')}
                            </li>
                        </ul>

                        <p className="info-paragraph">
                            {t('7.2')} <br />
                            {t('We are not responsible for the acts or omissions of third parties.')}
                        </p>

                        {/* Section 8 */}
                        <h2 className="info-subtitle">{t('8. INDEMNITY')}</h2>

                        <p className="info-paragraph">
                            {t('8.1')} <br />
                            {t('You agree to indemnify and hold us harmless from any claims, damages, or losses arising from your improper use of the Platform, violation of these Terms, or infringement of any third-party rights.')}
                        </p>

                        {/* Section 9 */}
                        <h2 className="info-subtitle">{t('9. GENERAL')}</h2>

                        <p className="info-paragraph">
                            {t('9.1')} <br />
                            {t('These Terms shall be governed by the laws of the Republic of Iraq, and any disputes arising from these Terms will be settled by the courts of Baghdad.')}
                        </p>
                    </div>
                </div>
            </div>
        </BaseLayout>
    );
}

export default TermsOfUse;

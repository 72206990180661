// src/index.jsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './i18n';

// Retrieve the saved language from localStorage, default to 'ar' if not set
const savedLanguage = localStorage.getItem('language') || 'ar';

// Set the text direction based on the saved language
document.body.dir = savedLanguage === 'ar' ? 'rtl' : 'ltr';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

// Add a simple console log for debugging
console.log('Rendering the app...');

try {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

  console.log('App rendered successfully!');
} catch (error) {
  console.error('Error rendering the app:', error);
}

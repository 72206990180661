// src/components/Base/Header.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import './Header.css';
import { useTranslation } from 'react-i18next';

function Header() {
  const [languageDropdownVisible, setLanguageDropdownVisible] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('العربية'); // Default to Arabic

  const isLoggedIn = true;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Load the selected language from local storage
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      const languageName = storedLanguage === 'ar' ? 'العربية' : 'English';
      setSelectedLanguage(languageName);
    }
  }, []);

  const handleLanguageDropdownClick = () => {
    setLanguageDropdownVisible(!languageDropdownVisible);
  };

  const handleLanguageSelect = (language) => {
    const langCode = language === 'English' ? 'en' : 'ar';
    setSelectedLanguage(language);
    localStorage.setItem('language', langCode); // Store language code in local storage
    document.body.dir = langCode === 'ar' ? 'rtl' : 'ltr'; // Set text direction
    i18n.changeLanguage(langCode); // Change i18n language
    setLanguageDropdownVisible(false); // Close the dropdown after selection
  };

  const handleClickOutside = (event) => {
    // Close the dropdown if the click is outside the dropdown
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setLanguageDropdownVisible(false);
    }
  };

  useEffect(() => {
    // Attach click event listener to close dropdown when clicking outside
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogoClick = () => {
    history.push('/');
  };

  return (
    <div className="header-container">
      <div className="header-item h-left">
        <div className="header-logo" onClick={handleLogoClick}>
          <img src="/assets/icons/logo.svg" alt={t('logoAlt')} style={{ width: "80px", height: "80px" }} />
        </div>
        <div className="header-separator"></div>
        <div className="language-dropdown" ref={dropdownRef} onClick={handleLanguageDropdownClick}>
          <div className="selected-language">
            {selectedLanguage}
          </div>
          <div className="dropdown-arrow">
            <img src="/assets/icons/arrow-down.svg" alt={t('dropdownArrowAlt')} style={{ width: "100%", height: "100%" }} />
          </div>
          {languageDropdownVisible && (
            <div className="dropdown-content">
              {selectedLanguage !== 'English' && (
                <div className="dropdown-item" onClick={() => handleLanguageSelect('English')}>
                  {t('English')}
                </div>
              )}
              {selectedLanguage !== 'العربية' && (
                <div className="dropdown-item" onClick={() => handleLanguageSelect('العربية')}>
                  {t('العربية')}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="header-separator"></div>
      </div>

      <div className="header-item h-right">
        <div className="header-button-large" id="headerPlaceAd">
          <div className="plus-icon">
            <img src="/assets/icons/place-ad.svg" alt={t('placeAdAlt')} style={{ width: "24px", height: "24px" }} />
          </div>
          <div className="header-text">{t('Place an Ad')}</div>
        </div>
        <div className="header-separator"></div>
        {isLoggedIn && (
          <>
            <div className="header-icon" id="headerChat">
              <img src="/assets/icons/inbox.svg" alt={t('chatAlt')} />
              <div className="header-icon-text">{t('Chat')}</div>
            </div>
            <div className="header-separator"></div>
            <div className="header-icon" id="headerFavorites">
              <img src="/assets/icons/bell.svg" alt={t('favoritesAlt')} />
              <div className="header-icon-text">{t('Favorites')}</div>
            </div>
          </>
        )}
        <div className="header-separator"></div>
        <div className="header-icon">
          <img src={'/assets/icons/user.svg'} alt={t('profileAlt')} />
          <div className="header-icon-text">{t('Profile')}</div>
        </div>
        {/* Removed dropdownVisible since it's not used elsewhere */}
      </div>
    </div>
  );
}

export default Header;

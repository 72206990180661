import React from 'react';
import './SearchBar.css';
import { useTranslation } from 'react-i18next';

function SearchBar() {
    const { t } = useTranslation();

    return (
        <div className="basic-search-bar">
            <input 
                type="text" 
                className="basic-search-bar-input" 
                placeholder={t("Find what interests you (e.g registration, functionality etc)")}
            />
            <div className="basic-search-bar-icon">
                <img src="/assets/icons/magnifying_glass.svg" alt="Search Icon" />
            </div>
        </div>
    );
}

export default SearchBar;

import React from 'react';
import Header from './Header';
import Footer from './Footer';

const BaseLayout = ({ children }) => {
    return (
        <div className="base-layout">
            <Header />
            <div className="content">
                {children}
            </div>
            <Footer />
        </div>
    );
};

export default BaseLayout;
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Support from './pages/Support';
import AboutUs from './pages/Information/AboutUs';
import PrivacyPolicy from './pages/Information/PrivacyPolicy';
import TermsOfUse from './pages/Information/TermsOfUse';
import ScrollToTop from './components/Common/ScrollToTop'; // Import the ScrollToTop component
import DeleteUserData from './pages/Information/DeleteUserData';
import './App.css';

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Place ScrollToTop inside Router but before Switch */}
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/support" component={Support} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/terms-of-use" component={TermsOfUse} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/user-request-delete-data" component={DeleteUserData} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;

import React from 'react';
import { useHistory } from 'react-router-dom';
import './Footer.css';
import { useTranslation } from 'react-i18next';

function Footer() {
    const history = useHistory();
    const { t } = useTranslation();

    const socialLinks = {
        tiktok: "https://www.tiktok.com/@bayzar.iq",
        facebook: "https://www.facebook.com/profile.php?id=61568316361851",
        instagram: "https://www.instagram.com/bayzar.iq/"
    };

    const appLinks = {
        gplay: "https://play.google.com/store",
        apple: "https://www.apple.com/app-store/"
    };

    const handleSocialIconClick = (id) => {
        if (socialLinks[id]) {
            window.open(socialLinks[id], '_blank');
        }
    };

    const handleAppBadgeClick = (id) => {
        if (appLinks[id]) {
            window.open(appLinks[id], '_blank');
        }
    };

    return (
        <footer className="footer-container">
            <div className="footer-sections">
                <div className="footer-section first">
                    <div className="logo">{t('Bayzar')}</div>
                    <div className="description">{t('The Most Organized Online Catalog in Iraq')}</div>
                    <div className="social-title">{t('Social')}</div>
                    <div className="social-icons">
                        <button
                            className="icon"
                            id="instagram"
                            onClick={() => handleSocialIconClick('instagram')}
                            aria-label="Instagram"
                        >
                            <img src="/assets/icons/social/instagram.svg" alt={t('Instagram')} />
                        </button>
                        <button
                            className="icon"
                            id="tiktok"
                            onClick={() => handleSocialIconClick('tiktok')}
                            aria-label="TikTok"
                        >
                            <img src="/assets/icons/social/tiktok.svg" alt={t('TikTok')} />
                        </button>
                        <button
                            className="icon"
                            id="facebook"
                            onClick={() => handleSocialIconClick('facebook')}
                            aria-label="Facebook"
                        >
                            <img src="/assets/icons/social/facebook.svg" alt={t('Facebook')} />
                        </button>
                    </div>
                </div>
                <div className="footer-section third">
                    <div className="section-title">{t('Company')}</div>
                    <div className="link" onClick={() => history.push('/about-us')}>{t('About us')}</div>
                    <div className="link" onClick={() => history.push('/support')}>{t('Support & Help Center')}</div>
                </div>
                <div className="footer-section fourth">
                    <div className="section-title">{t('Download App')}</div>
                    <div className="app-badges">
                        <button
                            className="app-badge"
                            id="gplay"
                            onClick={() => handleAppBadgeClick('gplay')}
                            aria-label="Google Play Store"
                        >
                            <img src="/assets/icons/social/gplay.svg" alt={t('Google Play')} />
                        </button>
                        <button
                            className="app-badge"
                            id="apple"
                            onClick={() => handleAppBadgeClick('apple')}
                            aria-label="Apple App Store"
                        >
                            <img src="/assets/icons/social/apple.svg" alt={t('App Store')} />
                        </button>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="footer-links">
                    <div className="link" onClick={() => history.push('/terms-of-use')}>{t('Terms of Use')}</div>
                    <div className="divider"></div>
                    <div className="link" onClick={() => history.push('/privacy-policy')}>{t('Privacy Policy')}</div>
                    <div className="divider"></div>
                    <div className="link" onClick={() => history.push('/user-request-delete-data')}>{t('Request Data Deletion')}</div>
                    <div className="divider"></div>
                </div>
                <div className="copyright">
                    {t('Copyright © 2024, Bayzar')}
                </div>
            </div>
        </footer>
    );
}

export default Footer;

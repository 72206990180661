// src/pages/PrivacyPolicy.tsx

import React from 'react';
import './Information.css';
import BaseLayout from '../../components/Base/BaseLayout';
import { useTranslation } from 'react-i18next';

function PrivacyPolicy() {
    const { t } = useTranslation();

    return (
        <BaseLayout>
            <div className="info-page">
                <div className="info-content">
                    <div className="info-text-container">
                        <h1 className="info-title">{t('Privacy Policy')}</h1>

                        {/* Introduction */}
                        <p className="info-paragraph">
                            {t('We highly value your privacy and are committed to protecting your personal information. Please read this Privacy Policy carefully before using our services. By continuing to use our platform, you consent to the practices outlined in this policy.')}
                        </p>

                        {/* Definitions Section */}
                        <h2 className="info-subtitle">{t('Definitions')}</h2>
                        <ul className="info-list">
                            <li className="info-list-item">
                                {t('"Services": Refers to all products, services, content, features, technologies, and functions offered by us, as well as related websites, applications, and services we provide.')}
                            </li>
                            <li className="info-list-item">
                                {t('"Platform": Includes our websites, mobile applications, and other online properties through which we provide our services.')}
                            </li>
                        </ul>

                        {/* Consent Paragraph */}
                        <p className="info-paragraph">
                            {t('By accessing and using Bayzar and its associated services, you explicitly consent to Bayzar collecting, using, disclosing, and retaining your personal data in accordance with this Privacy Policy and our Terms of Use.')}
                        </p>

                        {/* Amendment Clause */}
                        <p className="info-paragraph">
                            {t('Bayzar reserves the right to update this Privacy Policy from time to time. We recommend regularly reviewing this page to stay informed about any changes. Once changes are posted on our website, they will become effective immediately.')}
                        </p>

                        {/* Section 1 */}
                        <h2 className="info-subtitle">{t('1. Information We Collect')}</h2>
                        
                        <p className="info-paragraph">
                            {t('1.1')} <br />
                            {t('Account Information: When you create an account to access our services, we may collect details such as your name, phone number, and other basic information based on your registration method.')}
                        </p>
                        
                        <p className="info-paragraph">
                            {t('1.2')} <br />
                            {t('Automatically Collected Data: When you use our platform or engage with our services, we automatically gather information such as device details, location data, log information, clickstream data, and information collected through cookies and similar technologies.')}
                        </p>
                        
                        <p className="info-paragraph">
                            {t('1.3')} <br />
                            {t('Third-Party Data: In some cases, we may also obtain personal data from third parties or publicly available sources.')}
                        </p>

                        {/* Section 2 */}
                        <h2 className="info-subtitle">{t('2. How We Use Your Data')}</h2>
                        
                        <p className="info-paragraph">
                            {t('2.1')} <br />
                            {t('Your personal information is processed in compliance with applicable legal frameworks. Common reasons for processing include, but are not limited to:')}
                        </p>
                        
                        <ul className="info-list">
                            <li className="info-list-item">
                                {t('Fulfilling the contract we have with you.')}
                            </li>
                            <li className="info-list-item">
                                {t('Enhancing, personalizing, and delivering both current and future services and platform functionalities.')}
                            </li>
                            <li className="info-list-item">
                                {t('Detecting and preventing fraud, scams, or other illicit activities.')}
                            </li>
                            <li className="info-list-item">
                                {t('Introducing new features, services, and platform improvements.')}
                            </li>
                            <li className="info-list-item">
                                {t('Providing you with better support through customer service tools.')}
                            </li>
                            <li className="info-list-item">
                                {t('Complying with legal or regulatory obligations as required by relevant authorities.')}
                            </li>
                        </ul>

                        {/* Section 3 */}
                        <h2 className="info-subtitle">{t('3. Changes to This Privacy Policy')}</h2>
                        
                        <p className="info-paragraph">
                            {t('3.1')} <br />
                            {t('This policy may be modified periodically to reflect new legal or operational requirements. Any updates will be communicated by posting the revised policy on this page. If you do not agree with any of the changes, you must stop using the platform and services.')}
                        </p>

                        {/* Section 4 */}
                        <h2 className="info-subtitle">{t('4. Your Rights Regarding Your Data')}</h2>
                        
                        <p className="info-paragraph">
                            {t('4.1')} <br />
                            {t('Depending on your location and how we interact with you, you may have certain legal rights regarding your personal data. You can access your personal data or exercise your rights without incurring a fee, unless your request is clearly unreasonable, repetitive, or excessive, in which case we may charge a reasonable fee or refuse the request. We strive to respond to all legitimate requests within three months, and if we require more time, we will keep you informed.')}
                        </p>

                        {/* Section 5 */}
                        <h2 className="info-subtitle">{t('5. Communication and Marketing')}</h2>
                        
                        <p className="info-paragraph">
                            {t('5.1')} <br />
                            {t('We may communicate with you via email, SMS, or in-app notifications to manage your account and provide service-related updates. This includes but is not limited to confirming your registration, notifying you of listing statuses, and sending other transactional messages. These transactional messages are crucial for the operation of our services and cannot be opted out of.')}
                        </p>
                        
                        <p className="info-paragraph">
                            {t('5.2')} <br />
                            {t('When you register with Bayzar, or provide your information to access any of our services, you consent to receive marketing communications, which may include emails, messages, or phone calls related to promotional offers or new services.')}
                        </p>

                        {/* Section 6 */}
                        <h2 className="info-subtitle">{t('6. Data Sharing')}</h2>
                        
                        <p className="info-paragraph">
                            {t('6.1')} <br />
                            {t('We may share your personal data with third parties when necessary for the following purposes:')}
                        </p>
                        
                        <ul className="info-list">
                            <li className="info-list-item">
                                {t('Service Providers: We use third-party service providers to assist with delivering parts of our services, such as cloud storage solutions (e.g., Amazon Web Services) or others. These providers may be located inside or outside your current country of residence.')}
                            </li>
                            <li className="info-list-item">
                                {t('Legal Obligations: We may disclose your personal data to law enforcement, regulators, governmental bodies, or relevant third parties to comply with legal or regulatory requirements.')}
                            </li>
                            <li className="info-list-item">
                                {t('Business Transactions: In the event of a sale, merger, or transfer of part of our business or assets, the new owner may use your personal data in accordance with this policy.')}
                            </li>
                            <li className="info-list-item">
                                {t('Analytics Providers: We may share non-identifiable information with analytics and research partners to help us improve our platform and services. This information is anonymized and used solely for internal business purposes.')}
                            </li>
                        </ul>
                        
                        <p className="info-paragraph">
                            {t('6.2')} <br />
                            {t('When you post items for sale, certain personal information (such as your name, contact details, location, and pictures) may be visible to other users. Please note that this information can be shared further by those users, so exercise caution when sharing personal details.')}
                        </p>

                        {/* Section 7 */}
                        <h2 className="info-subtitle">{t('7. International Transfers')}</h2>
                        
                        <p className="info-paragraph">
                            {t('7.1')} <br />
                            {t('You agree that your personal data may be transferred to third parties within or outside your country. Although we take steps to protect your information, we cannot guarantee the security of data transmitted over the Internet, and you accept that transmission is at your own risk.')}
                        </p>

                        {/* Section 8 */}
                        <h2 className="info-subtitle">{t('8. Data Storage and Retention')}</h2>
                        
                        <p className="info-paragraph">
                            {t('8.1')} <br />
                            {t('Your data may be stored both inside and outside your country on secure servers. We retain your personal data only as long as necessary to fulfill the purposes for which it was collected, including legal, regulatory, and accounting requirements. The retention period for personal data is determined based on the nature, amount, and sensitivity of the data, the potential risks of unauthorized use or disclosure, and the purposes of processing.')}
                        </p>

                        {/* Section 9 */}
                        <h2 className="info-subtitle">{t('9. Security Measures')}</h2>
                        
                        <p className="info-paragraph">
                            {t('9.1')} <br />
                            {t('We implement appropriate technical and organizational measures to protect your personal data. Your information is stored on secure servers, and we regularly assess the security of our systems. Our security framework includes encryption (such as SSL technology for payment data), risk assessments, and regular testing to ensure your data is safeguarded against loss, unauthorized access, or disclosure.')}
                        </p>
                        
                        <p className="info-paragraph">
                            {t('9.2')} <br />
                            {t('Despite our efforts, the transmission of information via the internet is not entirely secure, and there is always a risk of unauthorized access. We advise caution when transmitting any personal data online.')}
                        </p>

                        {/* Section 10 */}
                        <h2 className="info-subtitle">{t('10. Contact Us')}</h2>
                        
                        <p className="info-paragraph">
                            {t('10.1')} <br />
                            {t('If you have any questions or would like to exercise your rights regarding your personal information, please contact our support team at')} <a href="mailto:admin@bayzar.com" className="info-link">admin@bayzar.com</a> {t('or visit the Help section on our platform.')}
                        </p>

                        {/* Section 11 */}
                        <h2 className="info-subtitle">{t('11. Updates to This Policy')}</h2>
                        
                        <p className="info-paragraph">
                            {t('11.1')} <br />
                            {t('We may update or amend this Privacy Policy periodically to align with legal requirements or changes in how we conduct our business. Please revisit this page regularly to stay informed about any modifications. Continued use of our services following any updates constitutes your acceptance of the changes.')}
                        </p>

                        {/* Section 12 */}
                        <h2 className="info-subtitle">{t('12. Acceptance of This Policy')}</h2>
                        
                        <p className="info-paragraph">
                            {t('12.1')} <br />
                            {t('By using our platform and services, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy and our Terms of Use. If you do not accept these terms, you are not authorized to access or use our platform and services.')}
                        </p>
                    </div>
                </div>
            </div>
        </BaseLayout>
    );
}

export default PrivacyPolicy;

// src/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

// Retrieve the saved language from localStorage, default to 'ar' if not set
const savedLanguage = localStorage.getItem('language') || 'ar';

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    lng: savedLanguage, // Initialize with the saved language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // Not needed for React
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // Path to your translation files
    },
    ns: ['translation'],
    defaultNS: 'translation',
    react: {
      useSuspense: false, // Disable suspense if not using it
    },
  }, (err, t) => {
    if (err) {
      console.error('i18n initialization failed:', err);
    } else {
      // Set the text direction based on the initialized language
      document.body.dir = savedLanguage === 'ar' ? 'rtl' : 'ltr';
    }
  });

export default i18n;

import React from 'react';
import './Information.css';
import BaseLayout from '../../components/Base/BaseLayout';
import { useTranslation } from 'react-i18next';

function AboutUs() {
    const { t } = useTranslation();
    return (
        <BaseLayout>
            <div className="info-page">
                <div className="info-content">
                    <div className="info-text-container">
                        <h1 className="info-title">{t('About Bayzar')}</h1>
                        <p className="info-paragraph">
                            {t('Bayzar is a home-grown Iraqi startup dedicated to creating an online marketplace that is simple, affordable, and user-friendly. Our mission is to make classified ads more accessible to everyone across Iraq, providing a platform where users can easily buy, sell, rent, and find services — all free of charge.')}
                        </p>
                        <p className="info-paragraph">
                            {t('We are committed to not only empowering private users, but small businesses as well by offering them greater visibility through affordable pricing options, helping them reach a broader audience and grow their operations. Bayzar is here to ensure that local entrepreneurs and small business owners have the tools they need to thrive in a competitive market.')}
                        </p>
                        <p className="info-paragraph">
                            {t('We believe in the power of community and the importance of local knowledge. Unlike larger platforms that cater to a broad region, Bayzar is laser-focused on the needs of Iraqi citizens. Whether you’re looking to sell a car, rent a home, or promote your business, our platform is designed to make the process as seamless and straightforward as possible.')}
                        </p>

                        <h2 className="info-subtitle">{t('Why Bayzar?')}</h2>
                        <ul className="info-list">
                            <li className="info-list-item">
                                {t('Built for Iraqis: We are a local company, deeply rooted in the Iraqi market. We understand your needs, challenges, and the opportunities unique to our community.')}
                            </li>
                            <li className="info-list-item">
                                {t('Free of Charge: At Bayzar, we’re committed to keeping the platform free of charge during our initial phases, making it easier for everyone to access and use.')}
                            </li>
                            <li className="info-list-item">
                                {t('Simplicity and Ease: Our platform is designed to be intuitive and easy to navigate, with a focus on ensuring that anyone, regardless of tech experience, can use it effortlessly.')}
                            </li>
                            <li className="info-list-item">
                                {t('Support for Small Businesses: We are passionate about supporting local businesses and entrepreneurs. With affordable or free listings, we’re helping Iraq’s economy grow from the ground up, providing small businesses with greater visibility at a cost that makes sense for them.')}
                            </li>
                            <li className="info-list-item">
                                {t('Community-Driven: Bayzar is built with feedback from local users, ensuring that the platform evolves in a way that best serves the Iraqi community.')}
                            </li>
                            <li className="info-list-item">
                                {t('Local Customer Service: We understand your language and your needs. Our support team is here to provide assistance that is not only professional but deeply contextualized to Iraq.')}
                            </li>
                        </ul>

                        <p className="info-paragraph">

                            {t('At Bayzar, we’re not just building a platform; we’re creating a community. Our vision is to provide every Iraqi citizen with a marketplace they can trust and rely on, empowering people and businesses alike to thrive in an ever-changing digital world.')}

                        </p>
                        <p className="info-paragraph">
                            <strong>
                                {t('Join us and experience the future of online classifieds in Iraq!')}
                            </strong>
                        </p>
                    </div>
                </div>
            </div>
        </BaseLayout>
    );
}

export default AboutUs;

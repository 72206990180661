import React from 'react';
import './Support.css';
import { useHistory } from 'react-router-dom';
import SupportCard from '../components/Support/SupportCard';
import SearchBar from '../components/Common/SearchBar';
import BaseLayout from '../components/Base/BaseLayout';
import { useTranslation } from 'react-i18next';

function Support() {
    const { t } = useTranslation();
    const history = useHistory();
    
    return (
        <BaseLayout>
            <div className="support-page">
                <div className="support-header-wrapper">
                    <div className="support-header">
                        <h1>{t('How can we help you?')}</h1>
                        <SearchBar />
                    </div>
                </div>
                <div className="support-cards">
                    <SupportCard
                        title={t('What is Bayzar')}
                        description={t('Get to know the Bayzar brand, and understand what it is all about.')}
                        imgSrc="/assets/icons/logo.svg"
                        onClick={() => history.push('about-us')}
                    />
                    <SupportCard
                        title={t('Terms of Use')}
                        description={t('Review the rules and guidelines for using our services.')}
                        imgSrc="/assets/icons/logo.svg"
                        onClick={() => history.push('terms-of-use')}
                    />
                    <SupportCard
                        title={t('Privacy Policy')}
                        description={t('Learn how we protect your personal information.')}
                        imgSrc="/assets/icons/logo.svg"
                        onClick={() => history.push('privacy-policy')}
                    />
                </div>
            </div>
        </BaseLayout>
    );
}

export default Support;

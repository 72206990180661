import React from 'react';
import BaseLayout from '../components/Base/BaseLayout';
import './Home.css';
import { useTranslation } from 'react-i18next';

const Home = () => {
    const { t } = useTranslation();

    const appLinks = {
        gplay: "https://play.google.com/store",
        apple: "https://www.apple.com/app-store/"
    };

    const handleAppBadgeClick = (id) => {
        if (appLinks[id]) {
            window.open(appLinks[id], '_blank');
        }
    };

    return (
        <BaseLayout>
            <div className="home-container">
                <div className="home-top-container">
                    <h1 className="coming-soon-title">{t('We Will Be Available Soon!')}</h1>
                    <p className="coming-soon-description">
                        {t('In the meantime, you can download our application from the stores below.')}
                    </p>
                    <div className="app-badges">
                        <div className="app-badge" id="gplay" onClick={() => handleAppBadgeClick('gplay')}>
                            <img src="/assets/icons/social/gplay.svg" alt={t('Google Play')} />
                        </div>
                        <div className="app-badge" id="apple" onClick={() => handleAppBadgeClick('apple')}>
                            <img src="/assets/icons/social/apple.svg" alt={t('App Store')} />
                        </div>
                    </div>
                </div>
            </div>
        </BaseLayout>
    );
};

export default Home;